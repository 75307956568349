import * as React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { useTheme } from "@mui/material/styles";

export default function HeaderText({ text }) {
    const theme = useTheme();
  theme.typography.h4 = {
    // make h4 responsive https://mui.com/material-ui/customization/typography/#font-size
    fontSize: "1.2rem",
    "@media (min-width:600px)": {
      fontSize: "1.5rem",
    },
    [theme.breakpoints.up("md")]: {
      fontSize: "2.4rem",
    },
  };
  return (
    <Typography
      variant="h4"
      color={theme.palette.text.textEyeGrabber}
      //  gutterBottom
      sx={{
        fontFamily: `"Libre Baskerville", system-ui`,
        fontWeight: 700,
        fontStyle: "normal",
      }}
    >
      {text}
    </Typography>
  );
}
