import * as React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { useTheme } from "@mui/material/styles";
import { Link } from "@mui/material";

export default function NormalText({ text, customColor, linkOptional, text2Optional }) {
    const theme = useTheme();
    theme.typography.h4 = {
      // make h4 responsive https://mui.com/material-ui/customization/typography/#font-size
      fontSize: "1.2rem",
      "@media (min-width:600px)": {
        fontSize: "1.5rem",
      },
      [theme.breakpoints.up("md")]: {
        fontSize: "2.4rem",
      },
    };
  return (
    <Typography
      variant="h4"
      color={customColor ? customColor : theme.palette.text.textEyeGrabber}
      gutterBottom
      sx={{
        fontFamily: `"Fira Code", monospace`,
        fontOpticalSizing: "auto",
        fontWeight: 300,
          fontStyle: "normal",
      }}
    >
          {text}
          {linkOptional ?
              <Link
                  href={linkOptional.href}
                  nderline="hover"
                  target="_blank" // opens link in new tab
                  color={theme.palette.text.hyperlinkText}
              >
                  {linkOptional.text}
              </Link> : null}
          {text2Optional ? text2Optional : null}
    </Typography>
  );
}
