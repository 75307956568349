import * as React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import NormalText from "../components/text/NormalText";
import HeaderText from "../components/text/HeaderText";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { Link } from "@mui/material";

export default function Home() {
  const theme = useTheme();
  const isLessThanLarge = useMediaQuery(theme.breakpoints.down("lg")); // https://stackoverflow.com/questions/72826309/get-current-material-ui-breakpoint-name
  const isLessThanMedium = useMediaQuery(theme.breakpoints.down("md")); // https://stackoverflow.com/questions/72826309/get-current-material-ui-breakpoint-name
  const welcomeText =
    "Welcome, wanderer! I create poems, stories, and custom web apps. I believe as humans, we have a responsibility to share and learn from each other, so here you can find my gift to the world: my life's work. I want YOU to benefit from the lessons I've learned as a creator and survivor. Contact me ";
  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
        }}
      >
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          sx={{
            // backgroundColor: 'red', // uncomment to make debugging easier
            alignContent: "flex-end",
            flexWrap: "wrap",
            minHeight: { xs: "20vh", md: "33vh", lg: "33vh" },
            // todo I need a gutter under the title text with mediuma and small sizes
          }}
        >
          <HeaderText text="Hi, I am S. Sepanski." />
        </Box>
        {
          // extra spacing for smaller screens
          isLessThanLarge ? (
            isLessThanMedium ? (
              <Box minHeight="3vh" />
            ) : (
              <Box minHeight="5vh" />
            )
          ) : (
            <Box minHeight="4vh" />
          )
        }
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          minHeight="30vh"
        >
          <Box maxWidth="85vw">
            <NormalText
              text={welcomeText}
              linkOptional={{
                href: "https://x.com/s__sepanski",
                text: "@s__sepanski",
              }}
              text2Optional="."
            />
          </Box>
        </Box>
      </Box>
    </>
  );
}
